import React from 'react';
import {
  QuickLinkButton,
} from "@components/buttons"

const GlobalQuickLinks = props =>  {
  return (
        <div>
          <h2>Music</h2>
          <p>
            <QuickLinkButton
              content={"Level Terrace Apartment EP on Spotify"}
              url={"https://open.spotify.com/album/1CCJpawjJgiafgf7r3okS6?si=BrePu0_fT76nrTPCBZ98tA"}
              icon={"play circle"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Bully LP on Spotify"}
              url={"https://open.spotify.com/album/67vTYPRVEg6WM47IBdBCnJ?si=rAZ-lwbgRGiILU5BnzP_oQ"}
              icon={"play circle"}
            />
          </p>
          <h2>Video</h2>
          <p>
            <QuickLinkButton
              content={"Little Miss Sparks video"}
              url={"https://youtu.be/3FdfF6btyHE"}
              icon={"video"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Potluck video"}
              url={"https://youtu.be/vytlqHNShAo"}
              icon={"video"}
            />
          </p>
          <h2>Social Media</h2>
          <p>
            <QuickLinkButton
              content={"TikTok"}
              url={"https://www.tiktok.com/@thesoogs"}
              icon={"video"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Facebook"}
              url={"https://www.facebook.com/the.soogs"}
              icon={"video"}
            />
          </p>
        </div>
  );
}

export default GlobalQuickLinks

GlobalQuickLinks.defaultProps = {
  textPadded: false,
  text: "",
}